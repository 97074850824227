<template>
  <el-dialog
    v-model="showOpeningBalanceDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-ledger-opening-balance-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-ledger-opening-balance-dialog-header__title">
        {{ t('common.openingBalance') }}
      </h4>
    </template>
    <div v-loading="dialogLoading">
      <el-scrollbar view-class="elv-ledger-opening-balance-content" :max-height="`calc(100vh - 240px)`">
        <div v-for="(list, index) in openingBalanceList" :key="index" class="elv-ledger-opening-balance-item">
          <div class="elv-ledger-opening-balance-item__line" :class="[list.title]">
            {{ t(`report.${list.title}`) }}
          </div>
          <div class="elv-ledger-opening-balance-item-content">
            <ul class="elv-ledger-opening-balance-item-account-list">
              <li
                v-for="(item, i) in openingBalanceForm[list.value as keyof typeof openingBalanceForm]"
                :key="i"
                class="elv-ledger-opening-balance-item-account-list__item"
              >
                <div class="elv-ledger-opening-balance-item-account-list__item-left">
                  <el-select
                    v-model="item.chartOfAccountId"
                    :placeholder="t('common.select')"
                    filterable
                    @change="onChangeChartOfAccount(list.value, list.code, i)"
                  >
                    <el-option
                      v-for="chartOfAccount in list.chartOfAccountList"
                      :key="chartOfAccount.chartOfAccountId"
                      :label="locale === 'en' ? chartOfAccount.name : chartOfAccount?.nameCN || chartOfAccount.name"
                      :value="chartOfAccount.chartOfAccountId"
                    />
                  </el-select>
                  <template v-for="(auxiliaryValue, auxiliaryIndex) in item.auxiliaryValueList" :key="auxiliaryIndex">
                    <ElvSelect
                      v-if="auxiliaryValue?.type === 'ENTITY_ACCOUNT'"
                      v-model="auxiliaryValue.entityAccountId"
                      clearable
                      class="is-shorter-entry"
                      :teleported="true"
                      :placeholder="t('placeholder.selectAccount')"
                      :options="accountNormalOptions"
                      :collapse-list="accountDeletedOptions"
                    >
                      <template #emptyContent>
                        <div class="elv-transactions-add-journal-form-account-item__empty">
                          <svgIcon name="zoom-area" width="24" height="24" />
                          <p>{{ t('message.noAccountFound') }}</p>
                          <router-link :to="{ name: 'entity-accounts-treasury' }">{{
                            t('button.goToAddAccount')
                          }}</router-link>
                        </div>
                      </template>
                    </ElvSelect>

                    <el-select
                      v-else-if="auxiliaryValue?.type === 'COUNTERPARTY'"
                      v-model="auxiliaryValue.counterpartyId"
                      filterable
                      remote
                      clearable
                      reserve-keyword
                      class="is-shorter-entry"
                      :loading="searchLoading"
                      :remote-method="remoteCounterpartyMethod"
                      :placeholder="t('placeholder.enterNameForSearch')"
                      popper-class="elv-counterparty-addTo-dialog-popper"
                    >
                      <el-option
                        v-for="counterparty in counterpartyOptions"
                        :key="counterparty.counterpartyId"
                        :label="counterparty.name"
                        :value="counterparty.counterpartyId"
                      />
                    </el-select>

                    <el-select
                      v-else-if="auxiliaryValue?.type === 'ITEM'"
                      v-model="auxiliaryValue.auxiliaryItemId"
                      filterable
                      remote
                      clearable
                      class="is-shorter-entry"
                      :placeholder="`${t('common.select')}...`"
                      popper-class="elv-counterparty-addTo-dialog-popper"
                    >
                      <el-option
                        v-for="auxiliaryItem in find(
                          currentChartOfAccount(list.code, item.chartOfAccountId)?.auxiliaryTypes ?? [],
                          { auxiliaryTypeId: auxiliaryValue.auxiliaryTypeId }
                        )?.auxiliaryItems || []"
                        :key="auxiliaryItem.auxiliaryItemId"
                        :label="auxiliaryItem.value"
                        :value="auxiliaryItem.auxiliaryItemId"
                      />
                    </el-select>
                  </template>

                  <el-dropdown
                    v-if="
                      item.chartOfAccountId &&
                      currentChartOfAccount(list.code, item.chartOfAccountId)?.auxiliaryTypes?.length &&
                      item.auxiliaryValueList.length <
                        currentChartOfAccount(list.code, item.chartOfAccountId)?.auxiliaryTypes.length
                    "
                    trigger="click"
                    :tabindex="i"
                    placement="bottom"
                    :show-arrow="false"
                    :teleported="false"
                    popper-class="elv-ledger-opening-balance-auxiliary__dropdown-popper"
                  >
                    <elv-button
                      type="primary"
                      plain
                      height="32"
                      class="elv-ledger-opening-balance-item-account-list__item-left__add"
                      ><SvgIcon name="add-default" width="16" height="16" fill="#838D95" />{{
                        t('button.auxiliary')
                      }}</elv-button
                    >
                    <template #dropdown>
                      <el-dropdown-menu
                        class="elv-ledger-opening-balance-auxiliary__dropdown elv-language"
                        size="large"
                      >
                        <el-dropdown-item
                          v-for="auxiliaryType in currentChartOfAccount(list.code, item.chartOfAccountId)
                            ?.auxiliaryTypes"
                          :key="auxiliaryType?.auxiliaryTypeId"
                          class="elv-ledger-opening-balance-auxiliary__dropdown--item"
                          :disabled="
                            item.auxiliaryValueList.some(
                              (auxiliaryValue: any) => auxiliaryValue.auxiliaryTypeId === auxiliaryType.auxiliaryTypeId
                            )
                          "
                          @click="onAddAuxiliaryValue(list.value, item.index, auxiliaryType)"
                          >{{ auxiliaryType.name }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div class="elv-ledger-opening-balance-item-account-list__item-right">
                  <div v-if="item.chartOfAccountId">
                    <CurrencySelect
                      v-model="item.currency"
                      :currencies-recommend-data="item?.currencyList ?? currenciesData"
                      :currency-types="currentChartOfAccount(list.code, item.chartOfAccountId)?.currencyTypes"
                      :disabled="
                        currentChartOfAccount(list.code, item.chartOfAccountId)?.currencyTypes[0] === 'REPORTING'
                      "
                      @onChangeCurrency="onChangeCurrency(list.value, i)"
                    />
                    <el-input v-model="item.amount" @input="onChangeAmount(list.value, i)" />
                  </div>
                  <SvgIcon
                    class="elv-ledger-opening-balance-item-account-list__item-right__delete"
                    name="sources-delete"
                    width="16"
                    height="16"
                    fill="#AAAFB6"
                    @click="onDeleteLine(list.value, item.index)"
                  />
                </div>
              </li>
            </ul>
            <div class="elv-ledger-opening-balance-item__operation">
              <elv-button type="primary" plain width="110" height="32" @click="onAddLine(list.value)"
                ><SvgIcon name="add-default" width="16" height="16" fill="#1753EB" />{{
                  t('button.addLine')
                }}</elv-button
              >
              <div class="elv-ledger-opening-balance-item__operation-info">
                <p>{{ t('common.total') }}</p>
                <span
                  v-loading="
                    openingBalanceForm[list.value as keyof typeof openingBalanceForm].some(
                      (item: any) => item?.calculateLoading
                    )
                  "
                  >{{
                    formatNumberToSignificantDigits(
                      totalAmount[list.value],
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>

      <div
        v-if="
          Object.keys(openingBalanceForm).some((key) => openingBalanceForm[key]?.length) &&
          (totalAmount.asset || totalAmount.liability || totalAmount.equity)
        "
        class="elv-ledger-opening-balance-dialog-constant"
        :class="{ 'is-error': !totalEqual }"
      >
        <div>
          <p>{{ t(`report.assets`) }}</p>
          {{
            totalAmount.asset !== '-'
              ? formatNumberToSignificantDigits(
                  Number(totalAmount.asset),
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              : '-'
          }}
        </div>
        <span>{{ totalEqual ? '=' : '≠' }}</span>
        <div>
          <p>{{ t(`report.liabilities`) }}</p>
          {{
            totalAmount.liability !== '-'
              ? formatNumberToSignificantDigits(
                  Number(totalAmount.liability),
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              : '-'
          }}
        </div>
        <span>+</span>
        <div>
          <p>{{ t(`report.equity`) }}</p>
          {{
            totalAmount.equity !== '-'
              ? formatNumberToSignificantDigits(
                  Number(totalAmount.equity),
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              : '-'
          }}
        </div>
      </div>
    </div>

    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="saveLoading"
        :disabled="saveDisabled"
        @click="onSaveBaseBalance"
      >
        {{ t('button.save') }}
      </elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import PriceApi from '@/api/PriceApi'
import ConfigApi from '@/api/ConfigApi'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import AccountsApi from '@/api/AccountsApi'
import CurrencySelect from './CurrencySelect.vue'
import { CurrencyItemType } from '#/ReportsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import { find, isNaN, uniqBy, debounce, isEmpty, cloneDeep } from 'lodash-es'
import ElvSelect from '@/components/Base/ElvSelect.vue'

const props = defineProps({
  chartOrAccountList: {
    type: Array<any>,
    default: () => {
      return []
    }
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const route = useRoute()
const { t, locale } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const accountStore = useAccountStore()

const showOpeningBalanceDialog = ref(false)
const showAuxiliaryCodeSelect = ref(false)
const saveLoading = ref(false)
const dialogLoading = ref(false)
const searchLoading = ref(false)
const isEdit = ref(false)
const openingBalanceForm: any = ref({
  asset: [],
  liability: [],
  equity: []
})
const currenciesData = ref<CurrencyItemType[]>([])
const counterpartyOptions: any = ref([])
const baseBalanceDetail: any = ref({})
const originCounterpartyOptions: any = ref([])

const auxiliaryCodeOptionData: any = ref({
  list: [],
  total: 0
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const openingBalanceList = computed(() => {
  return [
    {
      title: 'assets',
      value: 'asset',
      code: '100',
      chartOfAccountList: find(props.chartOrAccountList, { code: '100' })?.list ?? []
    },
    {
      title: 'liabilities',
      value: 'liability',
      code: '200',
      chartOfAccountList: find(props.chartOrAccountList, { code: '200' })?.list ?? []
    },
    {
      title: 'equity',
      value: 'equity',
      code: '300',
      chartOfAccountList: find(props.chartOrAccountList, { code: '300' })?.list ?? []
    }
  ]
})

const totalAmount: any = computed(() => {
  let data: any = {
    asset: 0,
    liability: 0,
    equity: 0
  }
  data.asset = openingBalanceForm.value.asset.reduce((acc: number, item: any) => {
    return new Big(acc).plus(item?.amountFC ? new Big(item?.amountFC) : '0')
  }, new Big(0))

  data.liability = openingBalanceForm.value.liability.reduce((acc: number, item: any) => {
    return new Big(acc).plus(item?.amountFC ? new Big(item?.amountFC) : '0')
  }, new Big(0))

  data.equity = openingBalanceForm.value.equity.reduce((acc: number, item: any) => {
    return new Big(acc).plus(item?.amountFC ? new Big(item?.amountFC) : '0')
  }, new Big(0))
  data = {
    asset: data.asset.toNumber().toFixed(2),
    liability: data.liability.toNumber(),
    equity: data.equity.toNumber()
  }
  return data
})

const totalEqual = computed(() => {
  if (!totalAmount.value.asset || (!totalAmount.value.liability && !totalAmount.value.equity)) return false
  const sum = new Big(totalAmount.value.liability).plus(totalAmount.value.equity).toNumber().toFixed(2)
  return new Big(totalAmount.value.asset).eq(new Big(sum))
})

const saveDisabled = computed(() => {
  if (
    isEdit.value &&
    !isEmpty(baseBalanceDetail.value) &&
    Object.keys(openingBalanceForm.value)?.every((key: string) => !openingBalanceForm.value[key]?.length)
  )
    return false
  if (dialogLoading.value || !totalEqual.value) return true
  let disabled = false
  Object.keys(openingBalanceForm.value).forEach((key) => {
    if (
      openingBalanceForm.value[key].some((item: any) => {
        if (item.chartOfAccountId && (item.amount === '' || item.amountFC === '')) return true
        return false
      })
    ) {
      disabled = true
    }
  })
  return disabled
})

const currentChartOfAccount = computed(() => {
  return (code: string, chartOfAccountId: string) => {
    return find(props.chartOrAccountList, { code })?.list.find(
      (item: any) => item.chartOfAccountId === chartOfAccountId
    )
  }
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform.logo,
      isCheck: false
    }
  })
})

const accountDeletedOptions = computed(() => {
  const accountList = accountStore.accountList
    .filter((item: any) => {
      return item.status !== 'NORMAL'
    })
    .map((item: any) => {
      return {
        id: item.entityAccountId,
        value: item.entityAccountId,
        label: item.name,
        icon: item.platform.logo,
        isCheck: false
      }
    })
  if (accountList.length) {
    return [
      {
        title: t('title.deletedAccounts'),
        list: accountList
      }
    ]
  }
  return []
})

const onChangeAmountFun = async (key: string, index: number) => {
  try {
    const openingBalanceData = openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index]
    if (isNaN(Number(openingBalanceData?.amount)) || openingBalanceData?.amount === '') {
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amountFC = ''
      return
    }
    // 计算价格汇率
    if (openingBalanceData.currency !== entityDetail.value?.defaultCurrency && openingBalanceData.currency !== '') {
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].calculateLoading = true
      const params = {
        currency: openingBalanceData.currency,
        datetimeStr: dayjs(entityStore.entityDetail?.startingDate).format('YYYY-MM-DD HH:mm:ss'),
        timezone: entityStore.entityDetail?.timezone,
        amount: openingBalanceData?.amount,
        entityId: entityId.value,
        quoteCurrency: entityStore.entityDetail?.defaultCurrency ?? 'USD'
      }
      const { data } = await PriceApi.getCurrencyPriceCalculate(params)
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amountFC = Number(data.amountFC)
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].calculateLoading = false
    } else if (openingBalanceData.currency === entityDetail.value?.defaultCurrency) {
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amountFC = Number(
        openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amount
      )
    } else {
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amountFC = ''
    }
  } catch (error) {
    console.log(error)
    openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].calculateLoading = false
  }
}

const onChangeAmount = debounce(onChangeAmountFun, 500)

const onChangeCurrency = (key: string, index: number) => {
  if (openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amount !== '') {
    onChangeAmountFun(key, index)
  }
}

const onAddAuxiliaryValue = (key: string, index: number, item: any) => {
  const auxiliaryValue: any = {
    type: item.type,
    auxiliaryTypeId: item.auxiliaryTypeId
  }
  switch (item.type) {
    case 'ENTITY_ACCOUNT':
      auxiliaryValue.entityAccountId = ''
      break
    case 'COUNTERPARTY':
      auxiliaryValue.counterpartyId = ''
      break
    case 'ITEM':
      auxiliaryValue.auxiliaryItemId = ''
      break
    default:
      break
  }
  openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].auxiliaryValueList.push(auxiliaryValue)
}

const onChangeChartOfAccount = async (key: string, code: string, index: number) => {
  if (openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].chartOfAccountId) {
    const { chartOfAccountId } = openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index]
    const chartOfAccount = currentChartOfAccount.value(code, chartOfAccountId)
    openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].auxiliaryValueList = []
    if (chartOfAccount?.isCashAccount || chartOfAccount?.currencyTypes?.[0] === 'REPORTING') {
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].currency =
        entityStore.entityDetail?.defaultCurrency
    } else {
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].currency = 'ETH'
    }
    openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amount = ''
    openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].amountFC = ''
    if (chartOfAccount?.currencyTypes?.[0] === 'REPORTING') return
    try {
      const params = {
        keywords: [],
        recommend: true,
        entityId: entityId.value,
        type: chartOfAccount?.currencyTypes ?? []
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      openingBalanceForm.value[key as keyof typeof openingBalanceForm.value][index].currencyList = data
    } catch (error) {
      console.log(error)
    }
  }
}

const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

const onAddLine = (key: string) => {
  const index = openingBalanceForm.value[key as keyof typeof openingBalanceForm.value].length
  openingBalanceForm.value[key as keyof typeof openingBalanceForm.value].push({
    chartOfAccountId: '',
    auxiliaryValueList: [],
    currency: '',
    amount: '',
    amountFC: '',
    calculateLoading: false,
    index
  })
}

const onDeleteLine = (key: string, index: number) => {
  openingBalanceForm.value[key as keyof typeof openingBalanceForm.value].splice(index, 1)
  openingBalanceForm.value[key as keyof typeof openingBalanceForm.value].forEach((item: any, i: number) => {
    item.index = i
  })
}

const onCheckOpeningBalanceDialog = () => {
  showOpeningBalanceDialog.value = !showOpeningBalanceDialog.value
}

const onCreateBaseBalance = async (params: any) => {
  try {
    await LedgerApi.createBaseBalance(entityId.value, params)
    ElMessage.success(t('message.success'))
    onCheckOpeningBalanceDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onEditBaseBalance = async (params: any) => {
  try {
    await LedgerApi.editBaseBalance(entityId.value, params)
    ElMessage.success(t('message.success'))
    onCheckOpeningBalanceDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onSaveBaseBalance = () => {
  saveLoading.value = true
  const params: any = cloneDeep(openingBalanceForm.value)
  Object.keys(params).forEach((key) => {
    params[key].forEach((item: any) => {
      delete item.index
      delete item?.currencyList
      delete item.amountFC
      delete item.calculateLoading
      item.auxiliaryValueList.forEach((auxiliaryValue: any) => {
        // eslint-disable-next-line no-param-reassign
        delete auxiliaryValue.type
      })
      item.auxiliaryValueList = item.auxiliaryValueList.filter((auxiliaryValue: any) => {
        return auxiliaryValue.entityAccountId || auxiliaryValue.counterpartyId || auxiliaryValue.auxiliaryItemId
      })
    })
    params[key] = params[key].filter((item: any) => {
      return item.chartOfAccountId
    })
  })
  if (!isEdit.value) {
    onCreateBaseBalance(params)
  } else {
    onEditBaseBalance(params)
  }
}

const fetchCurrenciesRecommend = async () => {
  try {
    const params = {
      recommend: true,
      type: ['FIAT', 'CRYPTO']
    }
    const { data } = await ConfigApi.searchCurrencyList(params)
    const list = [...cloneDeep(currenciesData.value), ...data]
    currenciesData.value = uniqBy(list, 'underlyingCurrencyId')
    return Promise.resolve(data)
  } catch (error: any) {
    console.log(error)
    currenciesData.value = []
    return Promise.reject(error)
  }
}

const fetchBaseBalanceDetail = async () => {
  try {
    const { data }: { data: any } = await LedgerApi.getBaseBalanceDetail(entityId.value)
    if (!isEmpty(data)) {
      const currencyList: CurrencyItemType[] = []
      Object.keys(data).forEach((key) => {
        openingBalanceForm.value[key] = data?.[key]?.list.map((item: any, index: number) => {
          const baseBalance: any = {
            index,
            currencyList: [item.underlyingCurrency],
            chartOfAccountId: item.chartOfAccountId,
            amount: String(item.amount),
            amountFC: String(item.amountFC),
            currency: item.currency,
            calculateLoading: false,
            auxiliaryValueList: []
          }
          currencyList.push(item.underlyingCurrency)
          baseBalance.auxiliaryValueList = item.auxiliaryValueList.map((val: any) => {
            const auxiliaryValue: any = {
              type: val?.auxiliaryType?.type,
              auxiliaryTypeId: val?.auxiliaryTypeId
            }
            switch (val?.auxiliaryType.type) {
              case 'ENTITY_ACCOUNT':
                auxiliaryValue.entityAccountId = val?.entityAccount?.entityAccountId ?? ''
                break
              case 'COUNTERPARTY':
                auxiliaryValue.counterpartyId = val.counterparty?.counterpartyId ?? ''
                break
              case 'ITEM':
                auxiliaryValue.auxiliaryItemId = val.auxiliaryItem?.auxiliaryItemId ?? ''
                break
              default:
                break
            }
            return auxiliaryValue
          })
          return baseBalance
        })
      })
      const list = [...cloneDeep(currenciesData.value), ...currencyList]
      currenciesData.value = uniqBy(list, 'underlyingCurrencyId')
      baseBalanceDetail.value = data
      isEdit.value = true
    }
    return Promise.resolve(data)
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

const fetchCounterpartyList = async () => {
  try {
    const params = {
      limit: 20,
      page: 1
    }
    const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
    originCounterpartyOptions.value = data.list
    counterpartyOptions.value = data.list
    return Promise.resolve(data)
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

const onCloseDialog = () => {
  showAuxiliaryCodeSelect.value = false
  isEdit.value = false
  openingBalanceForm.value = {
    asset: [],
    liability: [],
    equity: []
  }
  auxiliaryCodeOptionData.value = {
    list: [],
    total: 0
  }
}

defineExpose({ onCheckOpeningBalanceDialog })

watch(
  [() => showOpeningBalanceDialog.value],
  async () => {
    if (showOpeningBalanceDialog.value) {
      try {
        dialogLoading.value = true
        await Promise.all([
          fetchCurrenciesRecommend(),
          fetchBaseBalanceDetail(),
          fetchCounterpartyList(),
          accountStore.fetchEntityAccountList(entityId.value, { withDeleted: true })
        ])
        if (isEdit.value) {
          Object.keys(openingBalanceForm.value).forEach((key) => {
            openingBalanceForm.value[key].forEach((item: any) => {
              item.currencyList = currenciesData.value
            })
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        dialogLoading.value = false
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-ledger-opening-balance-dialog {
  width: 960px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-ledger-opening-balance-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .elv-ledger-opening-balance-content {
    width: 912px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .elv-ledger-opening-balance-item {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .elv-ledger-opening-balance-item__line {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 850px;
        height: 1px;
        background: #edf0f3;
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
      }

      &.liabilities::after {
        width: 829px;
      }

      &.equity::after {
        width: 852px;
      }
    }

    .elv-ledger-opening-balance-item-account-list {
      display: flex;
      flex-direction: column;
    }

    .elv-ledger-opening-balance-item-account-list__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 8px;
      width: 100%;
      min-height: 60px;
      border-bottom: 1px solid #edf0f3;

      &:nth-child(odd) {
        background: #f9fafb;
      }

      &:first-of-type {
        border-top: 1px solid #edf0f3;
      }

      .elv-ledger-opening-balance-item-account-list__item-left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        max-width: 678px;

        .el-select {
          width: fit-content;

          .el-select__wrapper {
            width: 200px;
          }
        }

        .elv-ledger-opening-balance-item-account-list__item-left__add {
          color: #838d95;

          svg {
            fill: #838d95;
          }
        }
      }

      .elv-ledger-opening-balance-item-account-list__item-right {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;

        > div {
          display: flex;
          align-items: center;
          background-color: transparent;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;

          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          &:not(.is-disabled):hover {
            .elv-ledger-opening-balance-currency-select {
              border: 1px solid #7596eb;
              border-right: 0px;
            }

            .el-input:not(.is-disabled) .el-input__wrapper {
              border: 1px solid #7596eb;
              border-left: 0px;
            }
          }

          .el-input__wrapper {
            width: 142px;
            border-left: 0px !important;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            padding: 0;
            padding-left: 10px;
            box-shadow: none;
          }
        }

        .elv-ledger-opening-balance-item-account-list__item-right__delete {
          cursor: pointer;

          &:hover {
            fill: #1753eb;
          }
        }
      }
    }

    .elv-ledger-opening-balance-item__operation {
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .elv-ledger-opening-balance-item__operation-info {
        display: flex;
        height: 28px;
        width: 214px;
        align-items: center;
        gap: 10px;

        p {
          width: 45px;
          color: #aaafb6;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }

        span {
          color: #1e2024;
          font-family: 'Barlow';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          position: relative;

          .el-loading-mask {
            background-color: transparent;
          }

          .el-loading-spinner {
            margin-top: 0px;
            top: 4px;
            right: -25px;
            text-align: right;
          }

          .circular {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .elv-ledger-opening-balance-dialog-constant {
    display: flex;
    height: 58px;
    padding: 8px 12px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-radius: 5px;
    background: #35c66f;
    margin: 16px auto 0;
    width: fit-content;

    div {
      display: flex;
      padding: 6px;
      min-width: 72px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #fff;
      font-family: 'Barlow';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      p {
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }

    span {
      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &.is-error {
      background: #eb5a52;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-ledger-opening-balance-auxiliary__dropdown-popper {
  overflow: hidden;
  transform: translateY(-11.5px);

  .elv-ledger-opening-balance-auxiliary__dropdown {
    padding: 0;
    /* 下拉选项 */
    .el-dropdown-menu__item {
      box-sizing: border-box;
      width: 150px;
      height: 32px;
      background: #ffffff;
      padding: 0px 8px;
      font-size: 12px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      justify-content: flex-start;
      align-items: center;

      &.is-disabled {
        background-color: #f9fafb;
      }
    }

    .el-dropdown-menu__item:not(.is-disabled):focus {
      background-color: $elv-color-F9FBFE;
      color: $elv-color-0E1420;
    }
  }
}
</style>
