<template>
  <el-dialog
    v-model="showCoaDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-ledger-coa-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-ledger-coa-dialog-header__title">
        {{ props.model === 'add' ? t('title.newAccount') : t('title.editAccount') }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-ledger-coa-content">
      <el-form ref="formRef" :model="chartOfAccountForm" label-position="top" :rules="rules">
        <el-form-item :label="t('title.parentAccount')" prop="parentChartOfAccountId">
          <el-select
            v-model="chartOfAccountForm.parentChartOfAccountId"
            :placeholder="t('common.select')"
            :disabled="props.model === 'edit'"
            @change="onChangeParentChartOfAccountId"
          >
            <el-option
              v-for="(item, index) in chartOrAccountList"
              :key="index"
              :label="locale === 'en' ? item.name : item?.nameCN || item.name"
              :value="item.chartOfAccountId"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('title.accountId')" prop="code">
          <el-input v-model="chartOfAccountForm.code" placeholder=" " maxlength="5">
            <template #prefix>{{ parentChartOfAccountData?.code }}-&nbsp;</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="t('title.accountName')" prop="name">
          <el-input v-model="chartOfAccountForm.name" placeholder=" " />
        </el-form-item>
        <el-form-item :label="t('title.balanceType')" prop="balanceType">
          <ul class="elv-ledger-coa-form-item__balance-list">
            <li
              :class="{
                'is-disabled':
                  parentChartOfAccountData?.code !== '600' || (props.model === 'edit' && unableEditChartOfAccount)
              }"
              @click="onChangeBalanceType('Dr')"
            >
              <template v-if="chartOfAccountForm.balanceType === 'Dr'">
                <img
                  v-if="
                    parentChartOfAccountData?.code !== '600' || (props.model === 'edit' && unableEditChartOfAccount)
                  "
                  src="@/assets/img/Checkbox-disabled.png"
                  alt="Checkbox"
                />
                <img v-else src="@/assets/img/Checkbox.png" alt="Checkbox" />
              </template>
              <div v-else class="is-empty" />
              <span>Dr.</span>
            </li>
            <li
              :class="{
                'is-disabled':
                  parentChartOfAccountData?.code !== '600' || (props.model === 'edit' && unableEditChartOfAccount)
              }"
              @click="onChangeBalanceType('Cr')"
            >
              <template v-if="chartOfAccountForm.balanceType === 'Cr'">
                <img
                  v-if="
                    parentChartOfAccountData?.code !== '600' || (props.model === 'edit' && unableEditChartOfAccount)
                  "
                  src="@/assets/img/Checkbox-disabled.png"
                  alt="Checkbox"
                />
                <img v-else src="@/assets/img/Checkbox.png" alt="Checkbox" />
              </template>
              <div v-else class="is-empty" />
              <span>Cr.</span>
            </li>
          </ul>
        </el-form-item>
        <el-form-item :label="t('report.Auxiliary code')" class="elv-ledger-coa-form-item__auxiliaryCode">
          <div
            v-if="
              !showAuxiliaryCodeSelect &&
              (props.model === 'add' || (props.model === 'edit' && !unableEditAuxiliaryCode))
            "
            class="elv-ledger-coa-form-item__auxiliaryCode-add"
            @click="showAuxiliaryCodeSelect = true"
          >
            <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />{{ t('button.addAuxiliaryCode') }}
          </div>
          <el-select
            v-else-if="showAuxiliaryCodeSelect || (props.model === 'edit' && unableEditAuxiliaryCode)"
            v-model="chartOfAccountForm.auxiliaryTypeIds"
            v-select-load-more="onLoadMoreAuxiliaryCode"
            multiple
            filterable
            collapse-tags
            collapse-tags-tooltip
            :multiple-limit="5"
            :max-collapse-tags="3"
            :placeholder="t('common.select')"
            popper-class="single-select-loadMore elv-ledger-coa-form-item__auxiliaryCode-popper"
            :disabled="props.model === 'edit' && unableEditAuxiliaryCode"
          >
            <template #label="{ label }">
              {{ label }}
            </template>
            <el-checkbox-group
              v-model="chartOfAccountForm.auxiliaryTypeIds"
              :disabled="props.model === 'edit' && unableEditAuxiliaryCode"
            >
              <el-option
                v-for="(item, index) in auxiliaryCodeOptionData.list"
                :key="index"
                :label="item.name"
                :value="item.auxiliaryTypeId"
              >
                <el-checkbox style="pointer-events: none" :value="item.auxiliaryTypeId">{{ item.name }}</el-checkbox>
              </el-option>
            </el-checkbox-group>
            <div v-if="auxiliaryCodeLoading" class="elv-ledger-coa-form-item__auxiliaryCode-loading">
              <el-icon class="is-loading">
                <Loading />
              </el-icon>
            </div>
            <template #footer>
              <div @click="onAddNewAuxiliaryType">+ {{ t('button.addNewAuxiliaryType') }}</div>
            </template>
          </el-select>
          <el-checkbox
            v-model="chartOfAccountForm.enableOriginalCurrency"
            :disabled="
              !['100', '200'].includes(parentChartOfAccountData?.code) ||
              (props.model === 'edit' && unableEditAuxiliaryCode)
            "
            >{{ t('message.enableOriginalCurrencyAccounting') }}
          </el-checkbox>
          <el-checkbox
            v-model="chartOfAccountForm.isCashAccount"
            :disabled="!['100'].includes(parentChartOfAccountData?.code)"
            >{{ t('message.isItACashAccount') }}</el-checkbox
          >
        </el-form-item>
        <el-form-item :label="t('common.description')">
          <el-input v-model="chartOfAccountForm.description" :rows="3" type="textarea" placeholder=" " />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="saveLoading"
        :disabled="dialogLoading"
        @click="onSaveChartOfAccount"
      >
        {{ t('button.save') }}
      </elv-button>
    </template>
  </el-dialog>
  <AuxiliaryTypeFormDialog
    ref="auxiliaryTypeFormDialogRef"
    model="add"
    :currentData="{}"
    @onResetList="getAuxiliaryCodeList(true)"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'
import { uniqBy, find, cloneDeep, isEmpty, isEqual } from 'lodash-es'
import AuxiliaryTypeFormDialog from '../../components/AuxiliaryTypeFormDialog.vue'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  chartOrAccountList: {
    type: Array<any>,
    default: () => {
      return []
    }
  },
  model: {
    type: String,
    default: 'add' // add, edit
  }
})

const route = useRoute()
const { t, locale } = useI18n()
const emit = defineEmits(['onResetList'])
const entityStore = useEntityStore()

const chartOfAccountDetail: any = ref({})
const showCoaDialog = ref(false)
const showAuxiliaryCodeSelect = ref(false)
const saveLoading = ref(false)
const checkStatusData: any = ref({})
const dialogLoading = ref(false)
const auxiliaryCodeLoading = ref(false)

const formRef = useTemplateRef<FormInstance>('formRef')
const auxiliaryTypeFormDialogRef = useTemplateRef('auxiliaryTypeFormDialogRef')
const auxiliaryCodeParams = reactive({
  page: 1,
  limit: 20
})

const chartOfAccountForm = ref({
  parentChartOfAccountId: '',
  code: '',
  name: '',
  balanceType: '',
  auxiliaryTypeIds: [] as string[],
  enableOriginalCurrency: true,
  isCashAccount: false,
  description: ''
})

const rules = reactive<FormRules>({
  code: [
    {
      required: true,
      trigger: 'blur',
      message: 'Account ID is required'
    }
  ],
  name: [
    {
      required: true,
      trigger: 'blur',
      message: 'Account name is required'
    }
  ]
})

const auxiliaryCodeOptionData: any = ref({
  list: [],
  total: 0
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const parentChartOfAccountData = computed(() => {
  return find(props.chartOrAccountList, { chartOfAccountId: chartOfAccountForm.value.parentChartOfAccountId })
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const unableEditChartOfAccount = computed(() => {
  return Object.values(checkStatusData.value).some((item) => item)
})

const unableEditAuxiliaryCode = computed(() => {
  const statusData = Object.fromEntries(Object.entries(checkStatusData.value).filter(([key]) => key !== 'JOURNAL_TYPE'))
  return Object.values(statusData).some((item) => item)
})

const onCheckCoaDialog = () => {
  showCoaDialog.value = !showCoaDialog.value
}

const onAddNewAuxiliaryType = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  auxiliaryTypeFormDialogRef.value?.onCheckAuxiliaryCodeTypeDialog()
}

const onChangeParentChartOfAccountId = () => {
  const DrChartOfAccounts = ['Assets', 'Expenses']
  const CrChartOfAccounts = ['Liabilities', 'Equity', 'Revenue']
  const enableOriginalCurrencyChartOfAccounts = ['Assets', 'Liabilities']
  if (DrChartOfAccounts.includes(parentChartOfAccountData.value?.name)) {
    chartOfAccountForm.value.balanceType = 'Dr'
  } else if (CrChartOfAccounts.includes(parentChartOfAccountData.value?.name)) {
    chartOfAccountForm.value.balanceType = 'Cr'
  }
  if (enableOriginalCurrencyChartOfAccounts.includes(parentChartOfAccountData.value?.name)) {
    chartOfAccountForm.value.enableOriginalCurrency = true
  } else {
    chartOfAccountForm.value.enableOriginalCurrency = false
  }
  chartOfAccountForm.value.isCashAccount = false
}

const onChangeBalanceType = (balanceType: string) => {
  if (parentChartOfAccountData.value?.code === '600') {
    chartOfAccountForm.value.balanceType = balanceType
  }
}

const onCreateChartOfAccount = async (params: any) => {
  try {
    await LedgerApi.createChartOfAccount(entityId.value, params)
    ElMessage.success(t('message.success'))
    onCheckCoaDialog()
    emit('onResetList')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onEditChartOfAccount = async (params: any) => {
  try {
    const data = cloneDeep(params)
    delete data.parentChartOfAccountId
    if (isEqual(chartOfAccountDetail.value.balanceType, params.balanceType)) {
      delete data.balanceType
    }
    if (unableEditAuxiliaryCode.value) {
      delete data.enableOriginalCurrency
    }
    if (isEqual(chartOfAccountDetail.value.auxiliaryTypeIds, params.auxiliaryTypeIds)) {
      delete data.auxiliaryTypeIds
    }
    await LedgerApi.editChartOfAccount(entityId.value, props.currentData?.chartOfAccountId, data)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    onCheckCoaDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onSaveChartOfAccount = () => {
  if (!formRef.value) return
  formRef.value?.validate(async (isValidate: boolean) => {
    if (isValidate) {
      saveLoading.value = true
      const enableOriginalCurrencyChartOfAccounts = ['Assets', 'Liabilities']
      const params: any = cloneDeep(chartOfAccountForm.value)
      params.code = `${parentChartOfAccountData.value.code}-${params.code}`
      if (!enableOriginalCurrencyChartOfAccounts.includes(parentChartOfAccountData.value?.name)) {
        delete params.enableOriginalCurrency
      }
      if (parentChartOfAccountData.value?.code !== '100') {
        delete params.isCashAccount
      }
      if (parentChartOfAccountData.value?.code !== '600') {
        delete params.balanceType
      }
      if (props.model === 'add') {
        onCreateChartOfAccount(params)
      } else {
        onEditChartOfAccount(params)
      }
    }
  })
}

const getAuxiliaryCodeList = async (update: boolean = false) => {
  try {
    auxiliaryCodeLoading.value = true
    if (update) {
      auxiliaryCodeParams.page +=
        auxiliaryCodeParams.page * auxiliaryCodeParams.limit - auxiliaryCodeOptionData.value.list.length === 0 ? 1 : 0
    }
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, auxiliaryCodeParams)
    const list = [...cloneDeep(auxiliaryCodeOptionData.value.list), ...data.list]
    auxiliaryCodeOptionData.value.total = data.total
    auxiliaryCodeOptionData.value.list = uniqBy(list, 'auxiliaryTypeId')
  } catch (error) {
    console.log(error)
  } finally {
    auxiliaryCodeLoading.value = false
  }
}

const onLoadMoreAuxiliaryCode = async () => {
  if (auxiliaryCodeOptionData.value.list.length < auxiliaryCodeOptionData.value.total && !auxiliaryCodeLoading.value) {
    auxiliaryCodeParams.page += 1
    await getAuxiliaryCodeList()
  }
}

const onCloseDialog = () => {
  showAuxiliaryCodeSelect.value = false
  formRef.value?.resetFields()
  chartOfAccountForm.value = {
    parentChartOfAccountId: '',
    code: '',
    name: '',
    balanceType: '',
    auxiliaryTypeIds: [],
    enableOriginalCurrency: true,
    isCashAccount: false,
    description: ''
  }
  auxiliaryCodeOptionData.value = {
    list: [],
    total: 0
  }
  auxiliaryCodeParams.page = 1
}

defineExpose({ onCheckCoaDialog })

watch(
  [() => showCoaDialog.value, () => props.currentData],
  async () => {
    if (showCoaDialog.value) {
      getAuxiliaryCodeList()
      if (props.model === 'add') {
        chartOfAccountForm.value.parentChartOfAccountId = props.chartOrAccountList?.[0]?.chartOfAccountId
        chartOfAccountForm.value.balanceType = 'Dr'
      }
      if (props.model === 'edit' && !isEmpty(props.currentData)) {
        dialogLoading.value = true
        try {
          const { data } = await LedgerApi.getChartOfAccountDetail(entityId.value, props.currentData?.chartOfAccountId)
          chartOfAccountDetail.value = data
          chartOfAccountForm.value = {
            parentChartOfAccountId: data.parentChartOfAccountId,
            code: data.code.split('-')[1],
            name: data.name,
            balanceType: data.defaultBalanceType,
            auxiliaryTypeIds: data.auxiliaryTypeIds ?? [],
            enableOriginalCurrency:
              !data?.currencyTypes?.includes('REPORTING') && ['ASSET', 'LIABILITY'].includes(data.type),
            isCashAccount: data.isCashAccount,
            description: data.description
          }
          showAuxiliaryCodeSelect.value = !!data.auxiliaryTypeIds?.length
          const response = await LedgerApi.checkChartOfAccountUsage(entityId.value, props.currentData?.chartOfAccountId)
          checkStatusData.value = Object.fromEntries(
            Object.entries(response.data).filter(([key]) => key !== 'TREASURY_ACCOUNT_MAPPING')
          )
        } catch (error) {
          console.log(error)
        } finally {
          dialogLoading.value = false
        }
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-ledger-coa-dialog {
  width: 480px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-ledger-coa-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-ledger-coa-content {
      border-radius: 4px;
    }

    .elv-ledger-coa-form-item__balance-list {
      display: flex;
      align-items: center;
      gap: 16px;

      li {
        width: 61px;
        display: flex;
        align-items: center;

        .is-empty {
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border: 1px solid #dde1e6;
          border-radius: 50%;
          transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
        }

        img {
          width: 16px;
          height: 16px;
          display: block;
        }

        &:not(.is-disabled):hover {
          cursor: pointer;

          .is-empty {
            border: 1px solid #7596eb;
          }
        }

        &.is-disabled {
          cursor: not-allowed;

          .is-empty {
            background-color: #f5f7fa;
            border: 1px solid #dde1e6;
          }

          span {
            color: #a8abb2;
          }
        }

        span {
          margin-left: 8px;
          color: #1e2024;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }

    .elv-ledger-coa-form-item__auxiliaryCode .el-form-item__content {
      gap: 16px;
    }

    .elv-ledger-coa-form-item__auxiliaryCode-add {
      display: flex;
      align-items: center;
      color: #1753eb;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &:not(.is-disabled):hover {
        cursor: pointer;
      }

      .is-disabled {
        cursor: not-allowed;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  .el-form.el-form--default {
    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-bottom: 8px;

      &::before {
        // 隐藏掉必填的小红星
        display: none;
      }
    }

    .el-input__wrapper {
      width: 432px;
      box-sizing: border-box;
    }

    .el-input {
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selection {
        .el-tag {
          display: flex;
          padding: 0px 8px;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          gap: 2px;
          border-radius: 3px;
          border: 1px solid #dde1e6;
          background: #edf0f3;

          .el-tag__content {
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }

          .el-icon.el-tag__close {
            color: #aaafb6;

            &:hover {
              color: #fff;
              background-color: #1753eb;
            }
          }
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-ledger-coa-form-item__auxiliaryCode-popper.el-popper {
  .elv-ledger-coa-form-item__auxiliaryCode-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.is-selected::after {
    display: none;
  }

  .el-select-dropdown__footer div {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
      cursor: pointer;
      color: #1753eb;
    }
  }
}

.elv-ledger-coa-dialog-tag-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;

  .el-tag {
    display: flex;
    padding: 0px 8px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 3px;
    border: 1px solid #dde1e6;
    background: #edf0f3;

    .el-tag__content {
      color: #1753eb;
      font-family: 'Barlow';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .el-icon.el-tag__close {
      color: #aaafb6;

      &:hover {
        color: #fff;
        background-color: #1753eb;
      }
    }
  }
}
</style>
